import { $getWithAuth, $postWithAuth } from "@/utils/request";
import { $myWsWithAuth } from "@/utils/request-ws";
import { BaseResponse, MsgAPI } from "./api";

export const $getUserMsgList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(MsgAPI.GetUserMsgList, params);
  return r;
};

export const $postUserMsgRead = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(MsgAPI.PostUserMsgRead, params);
  return r;
};

export const $getUserMsgLoad = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(MsgAPI.GetUserMsgLoad, params);
  return r;
};
export const $getUserMsgLoadBefore = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    MsgAPI.GetUserMsgLoadBefore,
    params
  );
  return r;
};

export const $getUserMsgWs = (
  params: any,
  onopen: () => void,
  onmessage: (evt: any) => void,
  onerror: (evt: any) => void,
  onclose: () => void
) => {
  return $myWsWithAuth(
    MsgAPI.GetUserMsgWs,
    params,
    onopen,
    onmessage,
    onerror,
    onclose
  );
};
