
import { defineComponent, onBeforeMount, onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useMainStore } from "@/store/index";
import { useDashBoardStore } from "@/store/dashboard";
import {
  BaseInterval,
  BaseSymbol,
  Dictionary,
  User,
  UserApi,
  UserMsg,
  userMsgResponse,
} from "@/api/model";
import { $checkToken } from "@/api/user";
import {
  $getDictionary,
  $getIntervals,
  $getSymbols,
  $getSymbolsMargin,
} from "@/api/base";
import {
  $getUserMsgList,
  $getUserMsgLoadBefore,
  $getUserMsgWs,
} from "./api/msg";
import { storeToRefs } from "pinia";

export default defineComponent({
  // 已启用类型推断
  name: "App",
  components: {},
  setup() {
    const router = useRouter();
    const mainStore = useMainStore();
    const dashboardStore = useDashBoardStore();

    onBeforeMount(async () => {
      // 自适应分辨率
      var root = document.documentElement;
      var rem = root.clientWidth / 19.2;
      root.style.fontSize = rem + "px";
      window.addEventListener("resize", () => {
        rem = root.clientWidth / 19.2;
        root.style.fontSize = rem + "px";
      });

      const { currentPositionAssetApiIdList } = storeToRefs(dashboardStore);
      const { userConfigData } = storeToRefs(mainStore);
      watch(
        () => currentPositionAssetApiIdList.value,
        (newList, oldList) => {
          if (newList.length == oldList.length) {
            return;
          }
          if (userConfigData.value == undefined) {
            return;
          }
          userConfigData.value.api_select_list = newList;
          mainStore.saveUserConfig();
        }
      );

      try {
        dashboardStore.refreshAstockNameMap();
        const res = await $checkToken();
        if (res.code == 200) {
          if (res.data == true) {
            //开启用户消息推送ws
            newWs();
            //获取用户配置信息
            await mainStore.refreshUserConfig();
            if (userConfigData.value != undefined) {
              currentPositionAssetApiIdList.value =
                userConfigData.value.api_select_list;
            }
            //刷新用户子账号信息
            await mainStore.refreshApiMap();
            //开启用户子账号ws
            mainStore.openApiStateWs();
          } else {
            localStorage.removeItem("user");
            mainStore.setUser(undefined);
          }
        } else {
          localStorage.removeItem("user");
          mainStore.setUser(undefined);
          currentPositionAssetApiIdList.value = [];
        }
      } catch (err) {
        // console.log(err);
      }
      // console.log(mainStore.getUserApiMap());

      try {
        const res = await $getSymbols({});
        if (res.code == 200) {
          // console.log(res);
          mainStore.setSymbols(res.data as BaseSymbol[]);
        }
      } catch (err) {
        // console.log(err);
      }
      try {
        const res = await $getIntervals({});
        if (res.code == 200) {
          mainStore.setIntervals(res.data as BaseInterval[]);
        }
      } catch (err) {
        // console.log(err);
      }
      try {
        const res = await $getDictionary({});
        if (res.code == 200) {
          mainStore.setDictionary(res.data as Dictionary[]);
        }
      } catch (err) {
        // console.log(err);
      }
      try {
        const res = await $getUserMsgList({});
        if (res.code == 200) {
          const list = res.data as UserMsg[];
          list.forEach((val) => {
            mainStore.addMessage(val);
          });
          for (let i = 1; i <= 3; i++) {
            let t = i.toString();
            let ml = mainStore.getMessage(t);
            if (ml.length > 0) {
              const innerRes = await $getUserMsgLoadBefore({
                type: t,
                last_time: ml[0].time,
              });
              if (innerRes.code == 200) {
                const mbl = innerRes.data as UserMsg[];
                mbl.forEach((val) => {
                  mainStore.addMessage(val);
                });
              }
            }
          }
        }
      } catch (err) {
        // console.log(err);
      }
      try {
        const res = await $getSymbolsMargin({});
        if (res.code == 200) {
          // console.log(res.data);
          mainStore.setSymbolsMargin(res.data);
        }
      } catch (err) {
        // console.log(err);
      }
    });
    const userJsonStr = localStorage.getItem("user");
    if (userJsonStr != null) {
      const user: User = JSON.parse(userJsonStr);
      mainStore.setUser(user);
    }
    //当进入一个页面是会触发导航守卫 router.beforeEach 事件
    router.beforeEach((to, from, next) => {
      // console.log(to.matched);
      document.title = "TradingX-让交易更简单！";
      if (to.name == "login") {
        if (mainStore.getUser() != undefined) {
          next("dashboard");
        } else {
          next();
        }
      } else {
        if (to.matched.length === 0) {
          next("/error?error=404");
        } else {
          if (to.meta.roles != undefined) {
            if (
              mainStore.role == undefined ||
              !to.meta.roles.includes(mainStore.role)
            ) {
              next("/error?error=403");
            } else {
              next();
            }
          } else {
            next();
          }
        }
      }

      // 跳转页面后 回到页面顶部
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });

    const handleUserMsgList = (allPage: userMsgResponse[]) => {
      allPage.forEach((page) => {
        if (page.pagenation.total == 0) {
          return;
        }
        let list = page.pagenation.results as UserMsg[];
        list.forEach((msg) => {
          mainStore.addMessage(msg);
        });
      });
    };

    let thisWs: WebSocket | undefined = undefined;
    const newWs = () => {
      const { ws } = $getUserMsgWs({}, onopen, onmessage, onerror, onclose);
      thisWs = ws;
    };

    //开启全局消息ws
    const onopen = () => {
      // console.log("msg websocket open");
    };

    const onmessage = (evt: any) => {
      let d: any = undefined;

      d = JSON.parse(evt.data);

      if (d != undefined) {
        handleUserMsgList(d as userMsgResponse[]);
      }
    };

    const onerror = (evt: any) => {
      console.error("Error Message:" + evt.data);
    };
    const onclose = () => {
      // console.log("Connection closed.");
    };

    onUnmounted(() => {
      thisWs?.close();
    });
    return {};
  },
});
