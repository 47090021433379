import { useMainStore } from "@/store";
// import ReconnectingWebSocket from "reconnecting-websocket";
export const $request_ws = {
  baseUrl: "/wssapi",
  timeout: 20000,
};

export const $myWs = (
  api: string,
  params: { [key: string]: any },
  onopen: () => void,
  onmessage: (evt: any) => void,
  onerror: (evt: any) => void,
  onclose: () => void
) => {
  let url = "wss://" + window.location.host + $request_ws.baseUrl + api + "?";
  for (const key in params) {
    url += key + "=" + encodeURI(params[key]) + "&";
  }
  // const ws = new ReconnectingWebSocket(url) as WebSocket;
  const ws = new WebSocket(url);
  //每3分钟ping一次保持连通
  const pingInterval = setInterval(() => {
    ws.send("ping");
  }, 3 * 60 * 1000);
  ws.onopen = onopen;
  ws.onmessage = (evt: any) => {
    if (evt.data == "pong") {
      return;
    }
    onmessage(evt);
  };
  ws.onerror = onerror;
  ws.onclose = () => {
    clearInterval(pingInterval);
    onclose();
  };
  return { ws, pingInterval };
};

export const $myWsWithAuth = (
  api: string,
  params: { [key: string]: any },
  onopen: () => void,
  onmessage: (evt: any) => void,
  onerror: (evt: any) => void,
  onclose: () => void
) => {
  const auth = useMainStore().token;
  if (auth == undefined) {
    return {
      msg: "Permission denied",
    };
  }
  const newParams = {
    ...params,
    token: auth,
  };
  const { ws, pingInterval } = $myWs(
    api,
    newParams,
    onopen,
    onmessage,
    onerror,
    onclose
  );
  return { ws, pingInterval };
};
