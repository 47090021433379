import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";

declare module "vue-router" {
  interface RouteMeta {
    title: string;
    roles?: string[];
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: "/home",
    name: "home",
    meta: {
      title: "主页",
    },
    component: () => import("@/views/home/home2.vue"),
  },
  {
    path: "/product",
    name: "product",
    meta: {
      title: "TradingX",
    },
    component: () => import("@/views/product/product.vue"),
  },
  {
    path: "/service/marketmaker",
    name: "marketmaker",
    meta: {
      title: "做市商",
    },
    component: () => import("@/views/service/marketmaker/marketmaker.vue"),
  },
  {
    path: "/service/digital-asset-management",
    name: "digitalAssetManagement",
    meta: {
      title: "数字资产管理",
    },
    component: () =>
      import(
        "@/views/service/digitalAssetManagement/digitalAssetManagement.vue"
      ),
  },
  {
    path: "/service/advisory",
    name: "advisory",
    meta: {
      title: "顾问服务",
    },
    component: () => import("@/views/service/advisory/advisory.vue"),
  },
  {
    path: "/about-us",
    name: "aboutUs",
    meta: {
      title: "关于我们",
    },
    component: () => import("@/views/About/AboutUs/AboutUs.vue"),
  },
  {
    path: "/about-career",
    name: "aboutCareer",
    meta: {
      title: "关于",
    },
    component: () => import("@/views/About/AboutCareer/AboutCareer.vue"),
  },
  {
    path: "/about-career/quantitative-researcher",
    name: "quantitativeResearcher",
    meta: {
      title: "量化研究员",
    },
    component: () =>
      import("@/views/About/AboutCareer/career/quantitativeResearcher.vue"),
  },
  {
    path: "/about-career/engineer",
    name: "careerEngineer",
    meta: {
      title: "资深工程师",
    },
    component: () => import("@/views/About/AboutCareer/career/engineer.vue"),
  },
  {
    path: "/about-career/trader",
    name: "careerTrade",
    meta: {
      title: "交易员",
    },
    component: () => import("@/views/About/AboutCareer/career/trader.vue"),
  },
  {
    path: "/about-career/application",
    name: "application",
    meta: {
      title: "职位申请",
    },
    component: () =>
      import("@/views/About/AboutCareer/career/application/application.vue"),
  },
  {
    path: "/contact-us",
    name: "contactUs",
    meta: {
      title: "联系我们",
    },
    component: () => import("@/views/contactus/contactus.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: "交易",
    },
    component: () => import("@/views/dashboard/dashboard.vue"),
  },
  {
    path: "/AIdashboard",
    name: "AIdashboard",
    meta: {
      title: "AI交易",
    },
    component: () => import("@/views/AIdashboard/AIdashboard.vue"),
  },
  {
    path: "/user",
    name: "user",
    meta: {
      title: "用户",
      roles: [
        "admin",
        "user",
        "asset",
        "finance",
        "observer",
        "risk",
        "finance_manage",
      ],
    },
    component: () => import("@/views/user/user.vue"),
  },
  {
    path: "/data",
    name: "data",
    meta: {
      title: "数据",
      roles: [
        "admin",
        "user",
        "asset",
        "finance",
        "observer",
        "risk",
        "finance_manage",
      ],
    },
    component: () => import("@/views/data/data.vue"),
  },
  {
    path: "/setup",
    name: "setup",
    meta: {
      title: "设置",
      // roles: ["admin", "user","asset"],
    },
    component: () => import("@/views/setup/setup.vue"),
  },
  {
    path: "/invite",
    name: "invite",
    meta: {
      title: "邀请",
    },
    component: () => import("@/views/inviteCode/invite.vue"),
  },
  {
    path: "/exkchart",
    name: "exkchart",
    meta: {
      title: "弹出K线",
    },
    props: (route) => ({ tab_id: route.query.q }),
    component: () => import("@/views/dashboard/content/kchart/PopKchart.vue"),
  },
  {
    path: "/exmutiplykchart",
    name: "exmutiplykchart",
    meta: {
      title: "弹出K线多图",
    },
    props: (route) => ({
      tab_id: route.query.s,
      chartNum: route.query.num,
    }),
    component: () =>
      import("@/views/dashboard/content/kchart/mutiplyKchat.vue"),
  },
  {
    path: "/exdepth",
    name: "exdepth",
    meta: {
      title: "弹出深度",
    },
    props: (route) => ({ tab_id: route.query.q }),
    component: () =>
      import("@/views/dashboard/content/depthtable/depthtable.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/error.vue"),
  },

  //根目录重定向到主页
  {
    path: "/",
    redirect: "/home",
  },
  //index目录重定向到主页
  {
    path: "/index",
    redirect: "/home",
  },
  {
    path: "/index.html",
    redirect: "/home",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
