import { Select } from "ant-design-vue";
import { ASTOCK_LIST } from "../astock";

export default {
  header: {
    menu: {
      tradingx: "TradingX",
      service: {
        title: "服务",
        subItems: {
          marketmaker: "做市商",
          digitalAssetManagement: "数字资产管理",
          advisory: "顾问服务",
        },
      },
      about: {
        title: "关于",
        subItems: {
          aboutUs: "关于我们",
          aboutCareer: "职业机会",
        },
      },
      contactUs: "联系我们",
    },
  },
  home: {
    menuItem: {
      production: "TradingX",
      service: "服务",
      about: "关于",
      contact: "联系我们",
    },
    intro: {
      whoWeArePart1: "我们是",
      whoWeArePart2: "全球领先的",
      whoWeArePart3: "数字资产",
      whoWeArePart4: "算法交易",
      whoWeArePart5: "公司",
      whatWeDo:
        "我们通过技术和交易，解决加密市场流动性和效率的问题，帮助成熟的投资者实现财务成果并推动加密经济的发展。",
      tradingVolumn: "交易量",
      scientificBasis: "科学依据",
      client: "客户",
    },
    services: {
      title: "科技与金融的碰撞",
      content: "构筑智能的、动态的加密交易系统，推动资本的高效配置和金融创新。",
      aiResearch: {
        title: "AI研究",
        subtitle: "释放AI的潜力",
        content:
          "我们深度探索AI，创造卓越算法与训练模型，利用AI的强大驱动寻找市场机会和规避风险，拓展金融边界，为算法交易带来更高的效益和成功。",
      },
      marketmaker: {
        title: "加密做市商",
        subtitle: "市场不可或缺的角色",
        content:
          "作为做市商，我们融合数学、金融学和计算机等专业学科，通过深厚的底蕴和卓越的交易能力为市场提供流动性，增强加密市场的可访问性，维持市场的稳定和效率。",
      },
      crypto: {
        title: "加密投资",
        subtitle: "财富增长的新范式",
        content:
          "我们擅长量化对冲交易，并保持技术创新和金融革命，为投资人提供机构级服务和更好的投资结果，共同塑造加密金融的未来。",
      },
    },
    cooperatorCarousel: {
      title: "我们与全球领先的企业合作",
    },
    exploration: {
      text: "从这里开始，了解加密市场的魅力",
    },
  },
  product: {
    header: {
      title: "数字资产管理的最佳拍档",
      content:
        "顶级的交易仪表盘，先进的策略、高质量的执行和深度的流动性聚合，为专业交易者提供白手套服务，在任何周期都能轻松交易。",
    },
    products: {
      trade: {
        title: "交易",
        content: {
          aggregateTransaction: {
            subtitle: "聚合交易",
            desc: "链接主流交易平台，聚合市场深度，实现同一平台执行复杂的交易计划。",
          },
          engine: {
            subtitle: "高效执行引擎",
            desc: "极速执行，保持低延迟和高吞吐量，确保交易的即时性和精确性。",
          },
        },
      },
      aiTrade: {
        title: "AI交易",
        content: {
          algorithmFrame: {
            subtitle: "领先的算法框架",
            desc: "融合金融工程、数学和AI等领域的研究，部署了领先的算法交易框架。",
          },
          riskSmartManagement: {
            subtitle: "智能风险管理",
            desc: "对潜在的市场风险进行量化分析，实时调整策略，保障交易安全。",
          },
          strategyOptimization: {
            subtitle: "自适应策略优化",
            desc: "AI系统根据实时市场快速识别机会与学习，自适应优化策略，确保持续的投资回报。",
          },
          solvingStrategy: {
            subtitle: "高级量化策略",
            desc: "AI技术为量化策略提供决策支持。做市策略借助AI，提供流动性，最大化收益并有效管理风险。",
          },
        },
      },
      accountManagement: {
        title: "统一账户管理",
        content: {
          assetManagement: {
            key: "account-1",
            subtitle: "一站式资金管理",
            desc: "跨平台的资产统一管理和结算，简化资金管理流程。",
          },
          maximizeEfficiency: {
            subtitle: "资金效率最大化",
            desc: "更高效地调度资金，实现资金的最优配置和利用。",
          },
        },
      },
      dataAnalysis: {
        title: "数据分析",
        content: {
          marketInsight: {
            subtitle: "深度市场洞察",
            desc: "深度数据挖掘和分析，形成市场洞察报告，辅助决策。",
          },
          transactionDataAnalysis: {
            subtitle: "交易数据分析",
            desc: "投资报告和反馈，包括投资回报率分析和执行质量评估，全面监控财务状况和投资组合表现。",
          },
        },
      },
    },
    startup: {
      header: {
        title: "为专业投资人设计的数字资产管理平台",
        content:
          "我们召集了各个学科最聪明的人，创造了探索数字资产管理的一切条件，共同实现我们的野心。",
      },
      list: {
        quantitativeTrading: "AI量化交易",
        deepLiquidity: "深度的流动性",
        customizableStrategy: "可定制的策略",
        investmentAnalysis: "数据与投资分析",
      },
      button: {
        text: "想突破投资边界吗？从这里开始",
      },
    },
  },
  marketMaker: {
    banner: {
      title: {
        part1: "做市商",
        part2: "为市场注入关键流动性",
      },
      content: "流动性是衡量资产是否快速交易且不引起价格波动的关键指标。",
    },
    intro: {
      title: "关于做市商",
      content:
        "做市商类似于“市场的中枢神经”，它们通过快速响应市场信号，协调买卖双方的交易需求，确保信息的即时传递和交易的顺畅执行，为市场创造流动性。做市商通过稳定价格和提供即时交易机会，对市场的稳定和效率至关重要。",
    },
    style: {
      title: "TradingX 的做市风格",
      content:
        "作为加密市场的做市商，TradingX降低资本配置与市场创新的成本，为市场注入关键流动性，给予全球交易者、投资机构和市场参与者无缝的交易体验。",
      styles: {
        flow: {
          subtitle: "流动性",
          content: "与全球领先的交易所深度合作，为其提供更强劲的流动性。",
        },
        dataAnalysis: {
          subtitle: "数据分析",
          content: "强大可靠的数据库及时智能生成报告，决策更快更准。",
        },
        algorithmTrade: {
          subtitle: "做市算法",
          content:
            "做市算法基于Avellaneda & Stoikov (AS)模型，结合最新的AI技术，为市场提供一种高效、精确的流动性解决方案。",
        },
        experience: {
          subtitle: "经验",
          content: "熟悉项目和交易所的交易生态需求，提供定制化服务。",
        },
      },
    },
    tip: "流动性对交易所来说极其重要。通常流动性低的订单簿价差和波动性都比较大，买家和卖家很难以合适的价格成交，只有确保资产的流动性，买卖双方才能随时快速交易。",
    contact: {
      title: "如果您需要",
      lyrics: {
        enhanceLiquidity: {
          title: "增强流动性",
          content: "减少买卖价差，提升交易效率。",
        },
        stabilizePrice: {
          title: "稳定价格",
          content: "缓解价格波动，维持交易生态的稳定性。",
        },
        manageCostsNRisks: {
          title: "管理成本与风险",
          content: "更精确的交易进出点，减少交易滑点。",
        },
        institutionalLevelServices: {
          title: "机构级服务",
          content: "支持大型的交易需求。",
        },
        solution: {
          title: "解决方案",
          content: "解决方案：流动性策略，代币经济学，加密投资等咨询。",
        },
      },
    },
    joinUs: {
      content: "需要一个靠谱的合作伙伴？我们的团队随时为您提供帮助！",
      button: {
        text: "联系我们",
      },
    },
  },
  digitalAssetManagement: {
    banner: {
      title: "数字资产管理",
      content: "AI算法交易体系的领先性和强劲的流动性带来持续稳定的投资回报",
    },
    management: {
      title: "资产管理",
      content: {
        part1: "利用我们经过验证的算法，无论在任何市场环境都能获得投资回报。",
        part2: "我们的服务与支持，让更多投资者有效地参与数字资产投资。",
      },
      AMIs: {
        am1: {
          subtitle: "流动性与市场接入",
          content:
            "广泛的市场接入和高效的流动性管理，确保客户资产在全球范围内实现最优配置。",
        },
        am2: {
          subtitle: "AI增强的策略",
          content:
            "我们通过AI增强策略能力，持续优化交易模型，实现超前的洞察和交易。",
        },
        am3: {
          subtitle: "稳健的投资表现",
          content:
            "凭借先进的量化交易策略和执行，不管在任何市场环境都可获得投资回报。",
        },
        am4: {
          subtitle: "风险管理与合规",
          content:
            "我们的全面风险管理框架结合合规性标准，确保投资的透明性和资产的安全性。",
        },
        am5: {
          subtitle: "第三方托管",
          content: "我们支持第三方托管服务，减少交易对手的风险。",
        },
        am6: {
          subtitle: "定制化投资方案",
          content:
            "我们结合客户的个人目标和风险偏好提供定制化的投资方案，实现个性化的资产管理。",
        },
      },
    },
    tip: "加密市场以其剧烈的价格波动、复杂的技术和不断变化的监管环境而闻名，这些因素共同构成了一个高度动荡和复杂的投资领域。在这样的背景下，投资者面临着识别和评估真正投资机会的挑战。",
    issue: {
      title: "投资者面临的问题",
      content: {
        c1: "数字资产流动性分散，仅用一个交易所能访问的市场深度极其有限",
        c2: "市场深度不透明，很难辨别市场数据和报告的真实性，导致投资失误",
        c3: "执行质量低，难以处理大型交易，容易导致高滑点等风险",
        c4: "面对市场波动时常受情绪驱动，作出非理性决策",
      },
    },
    sciManagement: {
      title: "科学的数字资产管理",
      content:
        "我们运用科学的方法解析数字资产市场，基于精确的交易模型进行战略性投资，即使在熊市也获得稳定的投资回报，为客户带来持续的财富增长。",
      SMs: {
        sm1: {
          subtitle: "算法执行",
          content: "自动遵循交易规则，高质量执行，减少人为失误。",
        },
        sm2: {
          subtitle: "交易系统",
          content:
            "我们的系统确保了低延遲、高吞吐量的全球交易，保障卓越交易性能。",
        },
        sm3: {
          subtitle: "数据网络",
          content: "辨别复杂的市场，是投资决策的可靠依据。",
        },
        sm4: {
          subtitle: "多元化策略",
          content:
            "因子研发是我们的专长，结合AI形成可预测，可自主优化的多元化策略。",
        },
        sm5: {
          subtitle: "风险管理",
          content: "完善的风控系统即时监控策略表现，确保投资计划稳定进行。",
        },
        sm6: {
          subtitle: "白手套服务",
          content: "24x7机构级白手套服务，为客户提供安心的数字资产投资体验。",
        },
      },
    },
    contact: {
      text: "如果您需要一个值得信赖的合作伙伴，请联系我们。",
      button: {
        text: "联系我们",
      },
    },
  },
  advisory: {
    banner: {
      title: "顾问服务",
      content1: "我们为棘手的问题找到优雅的解决方案",
      content2:
        "TradingX提供深入且全面的顾问服务，凭借丰富的专业知识，行业洞见和先进的技术，我们有效地把握市场动态，从客户利益出发，持续为客户创造价值。我们顾问服务的对象主要是金融机构，项目和高净值人群。",
    },
    advisory: {
      AIs: {
        FI: {
          subtitle: "金融机构",
          subContent:
            "我们了解金融机构在加密行业探索中的问题：即市场的复杂性、未来的不确定性以及跨学科知识的挑战。我们具备深厚的传统金融经验和多学科的视野与创新力，为金融机构量身定制个性化的解决策略，帮助金融机构在变幻莫测的加密市场中精准识别风险、捕捉机遇，实现资产的稳健增长与长期的成功。",
          subItems: {
            fi1: {
              name: "进入加密市场",
            },
            fi2: {
              name: "投资战略建议",
            },
            fi3: {
              name: "算法交易",
            },
            fi4: {
              name: "金融科技研究",
            },
            fi5: {
              name: "风险管理",
            },
            fi6: {
              name: "流动性管理",
            },
          },
        },
        project: {
          subtitle: "项目",
          subContent:
            "我们为项目方提供全面的咨询和定制方案，以应对代币发行和市场生命周期管理中的挑战。通过我们的深度流动性和技术，实现更高的市场占用率和风险管理。我们与有野心的，计划在一流交易所上市的项目合作。",
          subItems: {
            p1: {
              name: "流动性管理",
            },
            p2: {
              name: "代币经济学建议",
            },
            p3: {
              name: "投研与分析",
            },
            p4: {
              name: "生态系统建设",
            },
            p5: {
              name: "风险管理",
            },
            p6: {
              name: "财务方案",
            },
          },
        },
        HNWI: {
          subtitle: "高净值人群",
          subContent:
            "加密货币投资虽然充满潜力，但也伴随着一系列疑虑和挑战。市场的不透明性、技术的复杂性以及资产管理的专业性，往往让个人投资者感到困惑和不安。我们擅长解决这些困惑，降低加密投资门槛，减少投资者的参与摩擦。",
          subItems: {
            hnwi1: {
              name: "市场洞察与分析",
            },
            hnwi2: {
              name: "定制化资产管理",
            },
            hnwi3: {
              name: "算法交易",
            },
            hnwi4: {
              name: "金融科技研究",
            },
            hnwi5: {
              name: "风险管理",
            },
            hnwi6: {
              name: "24/7专属客服",
            },
          },
        },
      },
    },
    contact: {
      left: {
        h1: "让TradingX成为推动您数字资产战略发展的加速器。",
        button: "联系我们",
      },
      right: {
        h1: "关联服务",
        list: {
          l1: "TradingX",
          l2: "做市商",
          l3: "资产管理",
        },
      },
    },
  },
  aboutUs: {
    banner: {
      left: {
        title: "了解TradingX",
        content1:
          "TradingX是一家领先的算法交易公司，我们通过算法增强市场的无缝运作和可访问性，拓展金融的边界。作为全球流动性提供者，我们持续为市场注入活力，推动加密市场的效率，透明度和创新。",
        content2:
          "我们的成员专注于技术研发和金融创新，是充满活力的问题解决者，我们相信加密金融的未来，并保持耐心与创造。",
      },
      right: {
        stats: {
          stat1: {
            name: "交易量",
          },
          stat2: {
            name: "科学依据",
          },
          stat3: {
            name: "客户",
          },
          stat4: {
            name: "客户服务",
          },
        },
      },
    },
    duty: {
      title: "我们的使命",
      content1: "TradingX是全球领先的算法交易公司，",
      content2:
        "我们的使命不断拓展金融边界，赋能市场参与者，促进市场的透明度、公平性和效率。",
    },
    values: {
      header: {
        title: "我们的价值观",
        content:
          "我们的价值观汇聚了全球志同道合的人才，紧密协作创造领先的算法交易生态，共同促进去中心化世界的繁荣。",
      },
      valuesItems: {
        v1: {
          title: "诚信",
          content: "我们以最高的道德和法律标准为行为准则，保持真诚和负责任。",
        },
        v2: {
          title: "精确",
          content:
            "坚持精确，细致入微的态度，追求在研究、决策和执行过程中的高标准和高质量。",
        },
        v3: {
          title: "开放",
          content:
            "我们拥有开放的工作环境，倡导多元思维的碰撞与跨学科交流，组建卓越团队。",
        },
        v4: {
          title: "创新",
          content:
            "我们敢于突破边界，探索未知，通过创新不断自我超越，推动行业进步。",
        },
        v5: {
          title: "获胜",
          content:
            "我们不断精进，提升胜率，在市场博弈中战胜对手。不断探索新战略，帮客户战胜难题。",
        },
        v6: {
          title: "互惠",
          content:
            "我们与客户、合作伙伴及成员建立互惠共赢的长期合作，共同分享成长与成功。",
        },
      },
    },
    ourTeam: {
      header: {
        title: "我们的综合团队",
        content:
          "我们的团队由金融工程、数学科学、工程等领域的顶级人才构成，推动金融科技革新和实现共同的使命。",
      },
      ourTeamItems: {
        ot1: {
          title: "AI科研",
          content:
            "我们的专家团队致力于AI技术的研究，以实现交易算法的自我优化和市场适应性的提升。",
        },
        ot2: {
          title: "工程",
          content:
            "我们的工程团队专注于构建可扩展、高可靠性的基础设施，确保系统的高可用性和低延迟，满足大规模交易的需求。",
        },
        ot3: {
          title: "市场与交易",
          content:
            "市场与交易团队凭借对市场的深刻洞察，执行精确的交易操作，同时管理和对冲市场风险，以实现稳健的交易表现。",
        },
        ot4: {
          title: "量化研究",
          content:
            "量化研究团队擅长数学模型和统计，在动荡市场中的发现细微的联系，构建驱动交易的复杂算法。",
        },
        ot5: {
          title: "商业咨询",
          content:
            "我们的商业咨询团队提供定制化解决方案，帮助客户理解市场和制定策略，以实现商业目标。",
        },
      },
    },
    solve: {
      header: {
        title: "我们解决了什么",
        content: "通过我们的专业，解决复杂的市场问题",
      },
      solveArounds: {
        sa1: {
          name: "研发与部署",
        },
        sa2: {
          name: "风险管理",
        },
        sa3: {
          name: "投研与分析",
        },
        sa4: {
          name: "数字资产管理",
        },
        sa5: {
          name: "代币经济学建议",
        },
        sa6: {
          name: "流动性管理",
        },
        sa7: {
          name: "生态建议",
        },
        sa8: {
          name: "定制化服务",
        },
      },
    },
  },
  aboutCareer: {
    banner: {
      h1: "职业机会",
      h2: "创造迭代人生策略，实现自己的野心。",
      p1: {
        part1: "我们汇聚来自不同背景的人才，共同打造一个自由的、多元化的团队。",
        part2: "在探索未知和挑战对手无法解决的难题的过程中，实现我们的抱负。",
      },
      p2: "我们野心勃勃并且对加密行业充满热情，如果您也一样，请申请！",
    },
    job: {
      header: {
        title: "开放职位",
      },
      jobs: {
        quantitativeResearcher: {
          name: "量化研究员",
        },
        engineer: {
          name: "资深工程师",
        },
        trader: {
          name: "交易员",
        },
        applyButton: {
          text: "申请",
        },
      },
    },
    careers: {
      quantitativeResearcher: {
        name: "量化研究员",
        remote: {
          location: "香港",
          mode: "全职",
        },
        applyButton: {
          text: "申请职位",
        },
        finding: {
          f1: "我们寻找的是热爱数学并擅长编写自动交易算法的人。您应在创造和回测上有天赋，擅长生成Alpha和自动化策略。您将与交易员和开发人员密切合作，提出假设、收集数据并进行分析以开发新算法。",
          f2: "TradingX正在寻找资深量化研究员，这是一个加入全球领先算法交易公司难得的机会，我们重视不同背景的，有创造力的人才，并鼓励他们充分发挥自己的潜力。在TradingX，您将有机会与各个领域的天才们一起解决有意义的问题，并成为前沿领域的专家。我们努力照顾我们的员工，提供有竞争力的激励、福利和奖励。",
        },
        candidate: {
          title: "我们的理想候选人：",
          needs: {
            n1: {
              content: "2年以上量化研究经验，特别是在做市商定价和对冲策略方面",
            },
            n2: {
              content:
                "拥有计量经济学、数学、物理学、统计学、计算机科学或相关学科的硕士以上学位",
            },
            n3: {
              content:
                "对数字资产的市场微观结构有深刻的理解，精通至少一种编程语言，如Java，Python或C++",
            },
            n4: {
              content: "具备低延迟交易系统的经验、强大的数学和统计能力",
            },
            n5: {
              content: "出色的逻辑思维和问题解决能力，能在压力下工作并承担责任",
            },
            n6: {
              content:
                "敢于竞争，持续学习，积极主动，擅于协作，优秀的沟通技巧，英语能力强优先",
            },
          },
        },
        todos: {
          title: "您需要做的是：",
          td1: {
            content: "与交易员和工程团队合作，优化和创新交易策略",
          },
          td2: {
            content: "对数据进行深入研究和分析，识别和利用市场模式",
          },
          td3: {
            content: "设计和实现复杂的量化模型和自动化交易算法",
          },
        },
      },
      engineer: {
        name: "资深工程师",
        remote: {
          location: "香港",
          mode: "全职",
        },
        applyButton: {
          text: "申请职位",
        },
        finding: {
          f1: "我们的工程师与研究员和交易员合作，解决大规模复杂问题。我们的团队能够快速将想法付诸实施，市场也能迅速提供反馈。",
          f2: "TradingX正在寻找资深工程师，这是一个加入全球领先算法交易公司难得的机会，我们重视不同背景的，有创造力的人才，并鼓励他们充分发挥自己的潜力。在TradingX，您将有机会与各个领域的天才们一起解决有意义的问题，并成为前沿领域的专家。我们努力照顾我们的员工，提供有竞争力的激励、福利和奖励。",
        },
        candidate: {
          title: "我们的理想候选人：",
          needs: {
            n1: {
              content:
                "计算机科学、工程学、统计学、数学、物理学或相关领域的硕士学位",
            },
            n2: {
              content: "卓越的设计和编程技能，具备多种编程语言的能力",
            },
            n3: {
              content: "能够在复杂且技术含量高的团队环境中协助和有效沟通",
            },
            n4: {
              content: "对利用技术解决挑战性问题的好奇心和热情",
            },
            n5: {
              content: "具备较强的分析能力，熟悉概率和统计学",
            },
            n6: {
              content: "曾在金融科技公司工作，能进行区块链开发",
            },
          },
        },
        todos: {
          title: "您需要做的是：",
          td1: {
            content: "设计和开发金融市场产品，包括低延迟算法交易平台",
          },
          td2: {
            content: "实施、验证并执行软件测试",
          },
          td3: {
            content: "支持研究员和交易员，确保算法的实施",
          },
          td4: {
            content: "创建并维护文档，编写功能和设计规范",
          },
          td5: {
            content: "根据需求改进和定制系统",
          },
          td6: {
            content: "编码开发和调试，包括相关的自动化单元和集成测试",
          },
        },
      },
      trader: {
        name: "交易员",
        remote: {
          location: "香港",
          mode: "全职",
        },
        applyButton: {
          text: "申请职位",
        },
        finding: {
          f1: "我们的交易员与研究员和工程师合作，共同将伟大的想法付诸实践，取得更好的结果。",
          f2: "TradingX正在寻找交易员，这是一个加入全球领先算法交易公司难得的机会，我们重视不同背景的，有创造力的人才，并鼓励他们充分发挥自己的潜力。在TradingX，您将有机会与各个领域的天才们一起解决有意义的问题，并成为前沿领域的专家。我们努力照顾我们的员工，提供有竞争力的激励、福利和奖励。",
        },
        candidate: {
          title: "我们的理想候选人：",
          needs: {
            n1: {
              content: "金融、经济学、数学、统计学或相关领域的硕士学位",
            },
            n2: {
              content: "至少2年加密行业交易经验",
            },
            n3: {
              content: "对金融市场和交易策略有广泛的了解，尤其是做市",
            },
            n4: {
              content: "强大的分析、定量和人际交往能力",
            },
            n5: {
              content: "具备评估新代币相关的风险和机会的能力",
            },
            n6: {
              content:
                "能够管理复杂的交易和头寸，并随时了解加密市场趋势和监管变化",
            },
            n7: {
              content: "敢于竞争，持续学习，积极主动，擅于协作",
            },
            n8: {
              content: "优秀的沟通技巧，英语能力强优先",
            },
          },
        },
        todos: {
          title: "您需要做的是：",
          td1: {
            content: "具备丰富的数字资产交易知识以及在全球交易所进行交易的经验",
          },
          td2: {
            content: "与ICO发行人合作，促进一级市场交易并确保及时高效执行",
          },
          td3: {
            content: "确定潜在的做市机会和策略优化",
          },
          td4: {
            content: "洞察市场，制定计划，识别风险并大规模执行复杂交易",
          },
          td5: {
            content: "提高我们数字资产业务的整体盈利能力",
          },
        },
      },
      application: {
        title: "填写申请表",
        applyForm: {
          candidateInfo: {
            h1: "候选人信息",
            name: {
              label: "名称",
              placeholder: "请问如何称呼您",
            },
            resume: {
              label: "简历/履历",
              placeholder: "上传您的简历或履历 (.pdf, .doc)",
            },
            phone: {
              label: "电话",
              placeholder: "您的手机号",
            },
            email: {
              label: "邮箱",
              placeholder: "您的邮箱地址",
            },
            currentCompany: {
              label: "现就职公司",
              placeholder: "您的公司名称",
            },
          },
          candidateLinks: {
            h1: "链接",
            linkedIn: {
              label: "LinkedIn网址",
              placeholder: "您的领英网址",
            },
            github: {
              label: "Github网址",
              placeholder: "您的Github网址",
            },
            combine: {
              label: "组合网址",
              placeholder: "您的组合网址",
            },
            other: {
              label: "其他网址",
              placeholder: "您的其他网址",
            },
          },
          candidateExtra: {
            h1: "附加信息",
            p: "添加求职信或任何其他您想要分享的内容",
          },
          submitButton: {
            text: "提交申请表",
          },
          notification: {
            submit: {
              success: {
                message: "提交成功",
                description: "您的申请表已经成功提交！",
              },
              error: {
                message: "提交失败",
                description: "申请表提交失败，请联系管理员。",
              },
            },
          },
          Rules: {
            ResumeRule: {
              IsNotNull: "简历/履历不能为空",
              IsOversize: `简历文件过大，上限10M`,
            },
          },
        },
      },
    },
  },
  contactUs: {
    banner: {
      text: "联系TradingX",
    },
    contact: {
      h1: "请完成表单和我们联系",
      formItems: {
        firstName: {
          placeholder: "名*",
        },
        lastName: {
          placeholder: "姓*",
        },
        email: {
          placeholder: "电子邮件*",
        },
        telegram: {
          placeholder: "电报*",
        },
        company: {
          placeholder: "公司*",
        },
        position: {
          placeholder: "职位",
        },
        interest: {
          placeholder: "我感兴趣的是*",
          options: {
            opt1: {
              name: "TradingX",
            },
            opt2: {
              name: "资产管理",
            },
            opt3: {
              name: "做市商",
            },
            opt4: {
              name: "合作伙伴关系",
            },
          },
        },
        business: {
          placeholder: "业务*",
          options: {
            opt1: {
              name: "对冲基金",
            },
            opt2: {
              name: "风险投资基金",
            },
            opt3: {
              name: "机构财富管理人",
            },
            opt4: {
              name: "金融中介",
            },
            opt5: {
              name: "自营交易者",
            },
            opt6: {
              name: "金融基础设施提供商",
            },
            opt7: {
              name: "高净值个人",
            },
            opt8: {
              name: "其他",
            },
          },
        },
        assetRange: {
          placeholder: "管理资产规模范围*",
          options: {
            opt1: {
              name: "<1M",
            },
            opt2: {
              name: "1M-10M",
            },
            opt3: {
              name: "10M-100M",
            },
            opt4: {
              name: "100M-500M",
            },
            opt5: {
              name: "500M-1B",
            },
            opt6: {
              name: ">1B",
            },
          },
        },
        country: {
          placeholder: "国家*",
        },
        submitButton: {
          text: "提交",
        },
      },
    },
  },
  footer: {
    media: {
      title: "社交媒体",
    },
    production: {
      title: "产品",
      tradingx: "TradingX",
    },
    about: {
      title: "关于",
      aboutUs: "关于我们",
      career: "职业机会",
    },
    service: {
      title: "服务",
      marketMaker: "做市商",
      digitalAssetManagement: "数字资产管理",
      consultationService: "顾问服务",
      serviceObject: "",
    },
    contact: {
      title: "联系我们",
      contact: "联系",
    },
  },

  sider: {
    trade: "交易",
    account: "账户",
    AITrade: "AI交易",
    TradeData: "交易数据",
    data: "数据",
    Settings: "设置",
  },
  KChat: {},
  dashboardTrade: {
    PleaseSelectPosition: "请选择仓位",
    AllClosePosition: "所选一键平仓",
    SyncMode: "同步下单",
    Cont: "张",
    IsHideMin: "隐藏小额资产",
    Asset: "资产",
    Query: "查询",
    OpenLong: "开多",
    CloseShort: "平空",
    open: "开仓",
    close: "平仓",
    Spot: "现货",
    Index: "指数",
    Futures: "期货",
    Forex: "外汇",
    AStock: "A股",
    AStock_SH: "上证",
    AStock_SZ: "深证",
    CloseLong: "平多",
    OpenShort: "开空",
    USDFutures: "U本位合约",
    COINFutures: "币本位合约",
    Perpetual: "永续",
    Quarterly: "次季",
    Currently: "当季",
    DayChange: "涨跌",
    DayHigh: "最高价",
    DayLow: "最低价",
    DayVolumeone: "成交量",
    DayVolumetwo: "成交额",
    Day: "日",
    Shares: "股",

    DayOpenPrice: "今开",
    DayPreClosePrice: "昨收",
    DayUpperLimit: "涨停",
    DayLowerLimit: "跌停",

    Price: "价格",
    Amount: "数量",
    Total: "总额",
    Buy: "买入",
    Sell: "卖出",
    Limit: "限价",
    MaxBuy: "可开",
    Available: "可用",
    Balance: "余额",
    AcceptQuotesSpread: "允许价差",
    QuickMode: "快速模式",
    Market: "市价",
    LogIn: "前往登录",
    TriggerOrder: "止盈止损",
    TriggerPrice: "触发价",
    Tradeatthebestprice: "以市场最优价成交",
    ActivateAccount: "请先激活账户",
    SwitchMainAccount: "请先切换主账户",
    Markets: "行情",
    Depth: "深度",
    OpenOrders: "当前委托",
    SubAccount: "子账户",
    Pair: "交易对",
    Time1: "日期",
    Side: "方向",
    Amount1: "委托量",
    Total1: "委托额",
    FilledAmount: "成交量",
    TotalFilled: "成交额",
    AveragePrice: "成交均价",
    ExecutionTime: "执行时间",
    Status: "执行状态",
    OrderStatus: "订单状态",
    TimeInForce: "订单有效方式",
    ImpactPercent: "冲击量",
    OrderType: "订单类型",
    OrderQty: "数量",
    PositionSide: "持仓方向",
    TradeRate: "成交率",
    Action: "操作",
    CancelAllOrder: "一键撤单",
    OrderHistory: "历史委托",
    PositionHistory: "历史平仓",
    AveragePositionCost: "仓位成本均价",
    AverageClosePrice: "平仓均价",
    CloseAmount: "平仓数量",
    RealizedPnL: "已实现盈亏(USD)",
    Position: "仓位",
    Typenew: "仓位类型",
    OpenPositions: "当前持仓",
    AverageOpenPrice: "开仓均价",
    MarkPrice: "标记价格",
    Margin: "保证金",
    MarginRatio: "保证金率",
    UnrealizedPnL: "未实现盈亏",
    ExchangePosition: "交易所仓位",
    ExchangeOpenOrder: "交易所挂单",
    OpenOrder: "当前挂单",
    LiqPrice: "强平价格",
    Time: "委托时间",
    Type: "委托类型",
    CurrentTokensOnly: "隐藏其他币种",
    TriggerOrderLimit: "条件委托限价",
    TriggerOrderMarket: "条件委托市价",
    BaseVersion: "尊享版",
    ProVersion: "专业版",
    Details: "详情",
    Revoke: "撤销",
    NineRounds: {
      Name: "九转局",
      NineRoundsA: {
        Name: "九转局A",
        TwoPointMethod: "两点法",
        ThreePointMethod: "三点法",
      },
      NineRoundsD: {
        D1Name: "九转局D1",
        D2Name: "九转局D2",
        SpaceMethod: "空间法",
        TimeMethod: "时间法",
      },
      Delete: "删除图",
      MessageText: {
        RenderError: "无法绘制九转局，请重新切换时间或重新绘制",
        Open: "开",
        High: "高",
        Low: "低",
        Close: "收",
        Volume: "成交量",
        FirstPoint: "第一点",
        SecondPoint: "第二点",
        ThirdPoint: "第三点",
        NewestPrice: "最新价格",
        RenderTipsClickTwo: "请点击两个点",
        RenderTipsClickThree: "请点击三个点",
        ConfirmRender: "是否确认绘制九转局?",
        ConfirmReRender: "是否重新绘制九转局?",
        WillClear: "将清除现有九转局",
        Time: "时间",
        Price: "价格",
      },
    },
    Layout: {
      Name: "布局",
      Lock: "移动布局",
      AutoSave: "自动保存",
      Rename: "重命名",
      NewLayout: "新建布局",
      MyLayout: "我的布局",
      RecoverDefault: "恢复默认",
      ConfirmDelete: "确认删除该布局?",
      ConfirmDeleteTip: "此操作不可恢复。",
      Cancel: "取消",
      Confirm: "确认",
      SubMap: "分图",
      BrowserNotSupport: "当前用户浏览器不支持Canvas,请更换浏览器重试！",
      SaveSuccess: "保存成功",
      SaveFail: "保存失败",
      DeleteSuccess: "删除成功",
      DeleteFail: "删除失败",
    },
    RuleMessageTest: {
      NumRule: {
        IsNotNum: "请输入数字",
        IsIllegalNum: "请输入合法数字",
        IsNotNull: "不能为空",
      },
      EmailRule: {
        IsIllegalEmail: "请输入正确的邮箱地址",
        IsNotNull: "邮箱地址不能为空",
      },
      PhoneRule: {
        IsNotNull: "手机号不能为空",
        IsIllegalPhoneNumber: "请输入正确的手机号",
      },
    },
    Deribit: {
      Position: "仓位",
      OpenOrder: "未结订单",
      OrderHistory: "订单历史记录",
      PositionHistory: "仓位历史记录",
    },
  },
  views_login: {
    login: "登录",
    login_input_userName_placeholder: "请输入邮箱/手机号",
    login_input_correct_email: "请输入正确的邮箱",
    login_input_password_placeholder: "请输入密码",
    login_input_VerificationCode_placeholder: "请输入验证码",
    login_remember_password: "记住密码",
    login_forget_password: "忘记密码？",
    reset: "重置",
    reset_password_success: "重置密码成功",
    reset_password: "重置密码",
    login_register: "立即注册",
  },
  client_things: {
    TotalVolume: "总成交量",
    WeekVolume: "周成交量",
    TotalMMVolume: "做市商成交总量",
    OrderQuantity: "订单成交笔数",
    CommisionSaving: "总节省手续费",
    TotalRebate: "返佣总额",
    TotalSaving: "总节省成本",
    TradeNow: "去交易",
    client_present: "让交易更简单",
    client_present_long: "数字资产投资管理平台",
    standard: "BTC现货已获得SEC标准",
    standardMore: "详情",
    WhyTradingX: "TradingX的优势",
    Executeinaggregation: "高效聚合执行",
    Leadingmarketmakingtools: "全周期策略",
    DataAnalysis: "数据分析",
    ComplianceSecurity: "合规风控",
    Executeinaggregationnew:
      "一个界面一键执行不同交易所的交易计划，减少和交易所打交道。",
    Leadingmarketmakingtoolsnew:
      "领先的做市工具全自动交易机器人和半自动工具，帮助投资人无论在什么市场都能赢得优势。",
    DataAnalysisnew:
      "主流数字资产链上数据、DEXs和CEXs的交易数据及全球股市、汇市、黄金等交易数据的分析，帮助优化投资组合",
    ComplianceSecuritynew: "新加坡和香港证券牌照，让交易更安心",
    Announcement: "平台公告",
    UserGuide: "使用教程",
    ContactJoinUs: "联系/加盟我们",
  },
  components_myheader: {
    loginout: "退出登录",
    login: "登录",
    register: "注册",
    totalone: "24H交易总量",
    totaltwo: "24H智能交易量",
    totalthree: "24H机器人交易量",
    totalfour: "24H返佣总额",
    totalfive: "24H做市商成交量",
    referral: "邀请推荐",
    AllRead: "全部已读",
    NoMore: "没有更多了",
    TradeMessage: "交易通知",
    Announcement: "公告",
    loadMore: "加载更多",
    SystemMessage: "系统消息",
  },
  components_mysymbolselect: {
    search: "搜索",
  },
  views_signup: {
    email: "邮箱",
    phone: "手机",
    signup_input_userName: "请输入账号",
    signup_input_userName_placeholder_email: "请输入邮箱",
    signup_input_userName_placeholder_phone: "请输入手机号",
    signup_input_userName_placeholder_Password: "请输入密码",
    signup_input_userName_placeholder_confirmPassword: "请确认密码",
    signup_input_userName_placeholder_inviteCode: "请输入邀请码",
    signup_input_userName_placeholder_VerificationCode: "请输入验证码",
    signup_input_userName_placeholder_Code: "获取验证码",
    signup_register: "注册",
    signup_login: "使用已有账户登录",
    signup_signup_success: "注册成功",
    signup_email_phone: "注册邮箱/手机号",
    signup_back_login: "返回登录",
    signup_please_input_password: "请输入密码",
    signup_please_input_size_password: "请输入长度为8-20位的密码",
    signup_please_input_confirm_password: "请输入确认密码",
  },
  user_info: {
    AccountManagement: "账户管理",
    Phone: "手机",
    Level: "账户等级",
    Type: "账户类型",
    LoginLog: "登录日志",
    Add: "新增",
    Exchange: "交易所",
    AccountName: "账户名",
    APIKEY: "账户APIKEY",
    Secretkey: " 账户secretkey",
    ExpirationTime: "过期时间",
    PermissionList: "权限列表",
    IPList: "IP列表",
    CreateTime: "创建时间",
    UpdateTime: "更新时间",
    Action: "操作",
    ViewBalance: "查看余额",
    Edit: "编辑",
    Delete: "删除",

    AssetsManagement: "资产分析",
    FinancialAnalysis: "财务分析",

    StrategyAnalysis: "策略分析",
    SelectExchange: "选择交易所",
    SelectStrategyType: "选择策略类型",
    SumQty: "总成交额",
    TotalSumQty: "累计成交额",
    SumCost24H: "24H资金成本",
    SumQty24H: "24H成交额",
    MaxHold24H: "24H最大持仓",
    MaxHoldLong24H: "24H最大持仓(多)",
    MaxHoldShort24H: "24H最大持仓(空)",
    SumNetProfit24H: "24H已实现盈亏",
    SumUnrealizedProfit24H: "24H未实现盈亏",
    SumTotalProfit24H: "24H总盈亏",
    ProfitAndMaxDrawDownChart: "收益和最大回撤",
    NetProfitRate24H: "24H收益率",
    SumNetProfitRate: "累计收益率",
    LowWater: "低水位",
    MaxDrawDown24H: "24H最大回撤",

    NetValueChart: "复合收益率",
    DrawDownChart: "回撤曲线",
    ProfitChart: "盈亏曲线",
    NetValue: "资产净值",
    HighWater: "高水位",

    DrawDown: "回撤",
    Profit: "盈亏",
    TotalProfit: "累计盈亏",

    Date: "时间",
    Return: "收益率",
    GrossReturn: "毛收益率",
    Nav: "净值",
    Balance: "余额",
    Transfer: "划转",
    LastUpdateTime: "最后更新时间",

    AnnualReturn: "年化收益率",
    AnnualVolatility: "年化波动率",
    SharpeRatio: "夏普比率",
    MaxDrawDown: "最大回撤",

    Hour: "小时",
    Day: "日",
    Week: "周",
    Month: "月",
    Year: "年",
    TIME_7D: "7天",
    TIME_30D: "30天",
    TIME_90D: "90天",
    TIME_180D: "半年",
    TIME_365D: "一年",
    OTHER: "自定义",

    SelectAccountType: "选择账户类型",
    SelectTimeRange: "选择时间范围",
    SelectTimeDuration: "选择时间间隔",
    SelectSubAccount: "选择子账户",
    SelectStatisticsCurrency: "选择统计币种",

    LastDayPnL: "昨日盈亏",
    LastWeekPnL: "7天盈亏",
    LastMonthPnL: "30天盈亏",
    Chart: "图表",
    DailyPnL: "每日收益",
    CumulativePnL: "累计收益",
    PnLDistribution: "收益分布",
    Smart: "智能交易",
    Bots: "机器人",
    AssetsDistribution: "资产分布",
    PositionDistribution: "持仓分布",

    Traders: "交易员",
    Observer: "观察者",
    Risk: "风控账号",
    ToVerify: "去验证",
    ConfirmDelete: "是否确认删除？",
    ConfirmDeleteTips: "此操作无法撤销！",
    Confirm: "确定",
    Cancel: "取消",

    AccountBalance: "账户余额",
    Currency: "币种",

    AccountType: "账户类型",
    AvailableBalance: "可用余额",
    LockBalance: "锁定余额",
    MarginBalance: "保证金余额",
    WalletBalance: "钱包余额",
    MaxTransferableBalance: "最大可转出余额",
    Total: "总计",
    Record: "条记录",

    Account: "账户",
    LoginIP: "登录IP",
    LoginTime: "登录时间",

    ApiKey: {
      ApiForm: {
        NewAccount: "新增账户",
        EditAccount: "编辑账户",
        AccountName: "账户名",
        Exchange: "交易所",
        AccountApiKey: "账户API KEY",
        AccountSecretKey: "账户SECRET KEY",
        AccountPassPhrase: "账户Passphrase",
        AuthList: "权限列表",
        SpotTrade: "现货交易",
        FutureTrade: "合约交易",
        Reset: "重置",
        Rule: {
          NameMsg: "请输入账户名",
          ExchangeMsg: "请选择交易所",
          ApiKeyMsg: "请输入ApiKey",
          SecretKeyMsg: "请输入SecretKey",
          PassPhraseMsg: "请输入PassPhrase",
          AuthListMsg: "请选择权限",
        },
        Confirm: "确定",
        Cancel: "取消",
      },
    },
  },
  data_info: {
    Data: "数据",
    Greedindex: "贪婪指数",
  },
  setting_info: {
    System: "系统设置",
    Language: "语言设置",
    Currency: "货币设置",
    TimeZone: "时区选择",
    Notice: "通知设置",
    Trade: "交易通知",
    OpenTradeNotice: "是否开启交易通知",
    Announcement: "公告通知",
    OpenAnnouncementNotice: "是否开启交易通知",
    ON: "开",
    OFF: "关",
    Security: "安全设置",
    BindEmail: "绑定邮箱",
    BindedEmai: "已绑定邮箱",
    SMSVerification: "短信验证",
    EffectivelyProtectAccountSecurity: "短信验证可有效保护账户安全",
    EmailVerification: "邮箱验证",
    EffectivelyProtectEmailSecurity: "邮箱验证可有效保护账户安全",
    BindIP: "绑定IP",
    BindedIP: "已绑定IP",
    Change: "修改",
    ChangePassword: "修改密码",
    LanguageSetting: {
      SimplifiedChinese: "简体中文",
      TraditionalChinese: "繁體中文",
      English: "English",
    },
    SecuritySetting: {
      AccountPassword: "账户密码",
      PasswordLevel: "当前密码强度",
      PasswordLevelLow: "低",
      PasswordLevelMedium: "中",
      PasswordLevelHigh: "高",
    },

    PlaceHolderText: {
      OldPassword: "请输入旧密码",
      NewPassword: "请输入新密码",
      ConfirmPassword: "请确认新密码",
    },
  },
  PlaceHolderText: {
    Cancel: "取消",
    Confirm: "确定",
  },
  Dictionary: {
    EXECUTING: "正在执行",
    EXECUTED: "执行成功",
    CANCELING: "正在撤销",
    CANCELED: "已撤销",
    EXECUTION_ERROR: "执行错误",
    OPEN: "开仓",
    CLOSE: "平仓",
    BUY: "买入",
    SELL: "卖出",
    LONG: "多",
    SHORT: "空",
    BOTH: "双向",
    NORMAL_MODE: "正常模式",
    FILLED: "完全成交",
    LIMIT: "限价",
    MARKET: "市价",
    STOP_MARKET: "市价止损",
    TAKE_PROFIT_MARKET: "市价止盈",
    TAKE_PROFIT: "止盈",
    STOP_LOSS: "止损",
    PARTIALLY_FILLED: "部分成交",
    TRADING: "交易中",
    DIRECT_MODE: "直接模式",
    BINANCE_FUNDING: "资金",
    BINANCE_SPOT: "现货",
    BINANCE_MARGIN_CROSSED: "全仓杠杆",
    BINANCE_MARGIN_ISOLATED: "逐仓杠杆",
    BINANCE_FUTURE: "U本位合约",
    BINANCE_SWAP: "币本位合约",
    SPOT: "现货",
    SWAP: "币本位合约",
    FUTURE: "U本位合约",
    LINEAR: "U本位合约",
    INVERSE: "反向合约",
    spot: "现货",
    linear: "U本位合约",
    inverse: "反向合约",
    OKX_SPOT: "现货",
    OKX_SWAP: "永续合约",
    OKX_FUTURES: "交割合约",
    OPTION: "期权",
    INDEX: "指数",
    FUTURES: "期货",
    FOREX: "外汇",
    ASTOCK: "A股",
    EXECUTION_TIMEOUT: "执行超时",
    UNFILLED: "未成交",
    CONDITIONAL: "单向", // okx 策略委托单向止盈止损 conditional
    OCO: "双向", // okx 策略委托双向止盈止损 oco
    CONDITIONAL_OCO: "单向/双向",
    TRIGGER_ORDER_LIMIT: "条件委托限价",
    TRIGGER_ORDER_MARKET: "条件委托市价",
    TP_TRIGGER_PRICE: "止盈触发价",
    TP_ORDER_PRICE: "止盈委托价",
    TP_LIMIT: "限价止盈",
    TP_MARKET: "市价止盈",
    SL_TRIGGER_PRICE: "止损触发价",
    SL_ORDER_PRICE: "止损委托价",
    SL_LIMIT: "限价止损",
    SL_MARKET: "市价止损",
    WAITING: "待触发",
    EXPIRED: "失效",
    PLACE_ORDER: "挂单",
    FAILURE: "失败",
    PREPARING: "准备中",
    STOPPED: "已停止",
    STOPPING: "停止中",
    SELL_CLOSE: "卖出平仓",
    SELL_OPEN: "卖出开仓",
    BUY_CLOSE: "买入平仓",
    BUY_OPEN: "买入开仓",
    CLOSE_LONG: "平多",
    CLOSE_SHORT: "平空",
    INTERRUPTED: "中断",

    UNIFIED: "统一账号",
    CONTRACT: "合约账号",

    SAT_BINANCE_SPOT: "现货",
    SAT_BINANCE_MARGIN_CROSSED: "全仓杠杆",
    SAT_BINANCE_MARGIN_ISOLATED: "逐仓杠杆",
    SAT_BINANCE_FUTURE: "U本位合约",
    SAT_BINANCE_SWAP: "币本位合约",
    SAT_OKX_SPOT: "现货",
    SAT_OKX_SWAP: "永续合约",
    SAT_OKX_FUTURES: "交割合约",
    SAT_BYBIT_spot: "现货",
    SAT_BYBIT_linear: "U本位合约",
    SAT_BYBIT_inverse: "反向合约",

    AAT_BINANCE_FUNDING: "资金账户",
    AAT_BINANCE_SPOT: "现货账户",
    AAT_BINANCE_MARGIN_CROSSED: "全仓杠杆账户",
    AAT_BINANCE_MARGIN_ISOLATED: "逐仓杠杆账户",
    AAT_BINANCE_FUTURE: "U本位合约账户",
    AAT_BINANCE_SWAP: "币本位合约账户",
    AAT_BYBIT_FUNDING: "资金账户",
    AAT_BYBIT_UNIFIED: "统一账户",
    AAT_BYBIT_CONTRACT: "合约账户",
    AAT_OKX_FUNDING: "资金账户",
    AAT_OKX_SPOT: "交易账户",
  },
  Modal: {
    EmptyModal: {
      Message: "暂无数据",
    },
    InviteModal: {
      Invite: "邀请",
      Cancel: "取消",
      RateOfReturn: "收益率",
      InviteCode: "邀请码",
      Copy: "复制",
    },

    DeribitModal: {
      Cancel: "取消",
      Call: "看涨",
      Put: "看跌",
      Amount: "数量",
      Position: "持仓",
      LimitPrice: "限价",
      AdvancedOrderForm: "高级委托",
      TimeInForce: "有效方式",
      Reduce: "只减仓",
      Post: "只做Maker",
      BuyMargin: "买入保证金",
      SellMargin: "卖出保证金",
      ContractDetails: "合约详情",
      PriceSource: "价格来源",
      ContractSize: "合约大小",
      MinOrderSize: "最小下单量",
      TickSize: "最小变动价位",
      SettlementCurrency: "结算货币",
      ExpiryDate: "到期日",
      MinSellPrice: "最小卖出价格",
      MaxBuyPrice: "最大买入价格",
      Delta: "Delta",
      Gamma: "Gamma",
      Vega: "Vega",
      Theta: "Theta",
      Rho: "Rho",
      OrderBook: "订单簿",
      MarkPrice: "标记价格",
      the24hLow: "24h最低价",
      the24hHigh: "24h最高价",
      the24hVolume: "24h成交量",
      the24hChange: "24h涨跌幅",
      Total: "总计",
      BidSize: "买入量",
      BidPrice: "买入价",
      AskPrice: "卖出价",
      AskSize: "卖出量",
      RecentTrade: "最新成交",
      Chart: "图表",
      Buy: "买入",
      Sell: "卖出",
    },
    WarningModal: {
      PriceAlert: "价格预警",
      Monitoring: "监控中",
      MonitoringRecord: "监控记录",
      NewAlert: "新建预警",
      Cancel: "取消",
      PriceAlertSettings: "价格预警设置",
      Save: "保存",
      Load: "加载",
      TransactionType: "交易类型",
      PleaseSelect: "请选择",
      NewestPrice: "最新价格",
      TriggerPrice: "触发价格",
      DownTo: "下跌至",
      UpTo: "上涨至",
      RangePrice: "区间价",
      Amplitude: "振幅",
      WarningDuration: "预警时长",
      Alert: "预警",
      ExpirationTime: "失效时间",
      OneMinute: "1分钟",
      CloseManually: "手动关闭",
      WebSide: "Web端",
      AppSide: "App端",
      TriggeringConditions: "触发条件",
      PriceAtSetup: "设置时价格",
      CreatedOrExpired: "创建时间/过期时间",
      Status: "状态",
      Action: "操作",
      EffectiveTime: "生效时间",
    },
    LeverModal: {
      LevelTransfer: "杠杆划转",
      Transfer: "划转",
      Cancel: "取消",
      From: "从",
      To: "至",
      Currency: "币种",
      AvailableFunds: "可用资金",
      TransferAmount: "划转数量",
      All: "全部",
      IsolateAccount: "逐仓账户",
      FullAccount: "全仓账户",
      SpotAccount: "现货账户",
    },
    AIModal: {
      Spot: "现货",
      Isolate: "逐仓",
      Full: "全仓",
      BrrowAndRepay: "借/还",
      PleaseLogin: "请先登录！",
    },
    CiculateModal: {
      Spot: "现货",
      Isolate: "逐仓",
      Full: "全仓",
      MarginBrrowAndRepay: "杠杆借还",
      Confirm: "确定",
      Cancel: "取消",
      LoanAmount: "借款数量",
      CurrentlyAvailableToBorrowTips:
        "系统可借充足情况下用户账户当前最大可借额度",
      CurrentlyAvailableToBorrow: "当前可借",
      MaxAvailableToBorrowTips: "平台限制的用户当前等级可以借的额度",
      MaxAvailableToBorrow: "最大可借",
      HourlyInterestRate: "小时利率",
      HourlyInterestRateTips:
        "借入资产时会立即产生1小时的利息，并且每小时会记息一次(不满一小时按一小时计算)",
      Loan: "借款",
      Repay: "还款",
      Available: "可用",
      Borrowed: "已借",
      NetAssets: "净资产",
      CurrentInterest: "当前利息",
      RepaymentAmount: "还款数量",
      MaximumRepayable: "最大可还",
      OperationSuccessful: "操作成功",
      OperationFailed: "操作失败",
    },
    LeverageToolsModal: {
      Isolate: "逐仓",
      Full: "全仓",
      MarginModel: "保证金模式",
      Confirm: "确定",
      MarginModelTips1: "调整保证金模式仅对当前合约生效。",
      FullMarginTipsTitle: "全仓保证金模式:",
      FullMarginTipsContent:
        "保证金资产相同的全仓仓位共享该资产的全仓保证金。在强平事件中，交易者可能会损失全部该保证金和该保证金资产下的所有全仓仓位。",
      IsolateMarginTipsTitle: "逐仓保证金模式:",
      IsolateMarginTipsContent:
        "一定数量保证金被分配到仓位上。如果仓位保证金亏损到低于维持保证金的水平，仓位将被强平。在逐仓模式下，您可以为这个仓位添加和减少保证金。",
      Cancel: "取消",
      LeverageSetting: "杠杆率设置",
      CurrentLeveragePosition: "当前杠杆倍数最高可持有头寸",
      CurrentLeveragePositionTips1: "杠杆调整将同时影响当前仓位和挂单的杠杆。",
      CurrentLeveragePositionTips2:
        "选择超过 [10x] 杠杆交易会增加强行平仓风险，请注意相关风险。",
      ModelSetting: "模式设置",
      PositionModel: "仓位模式",
      OneWayPosition: "单向持仓",
      OneWayPositionTips: "单向持仓模式下，一个合约只允许持有一个方向的仓位。",
      TwoWayPosition: "双向持仓",
      TwoWayPositionTips:
        "双向持仓模式下，一个合约可允许同时持有多空两个方向的仓位，并且同一合约下不同方向仓位风险对冲。",
      AssetModel: "资产模式",
      SingleModel: "单币种保证金模式",
      SingleModelTips1: "仅支持使用合约的保证金资产交易U本位合约。",
      SingleModelTips2: "保证金资产相同的全仓仓位间盈亏互相抵消。",
      SingleModelTips3: "支持全仓和逐仓。",
      CombineModel: "联合保证金模式",
      CombineModelTips1: "可跨保证金资产交易U本位合约。",
      CombineModelTips2: "不同保证金资产全仓仓位间盈亏相互抵消。",
      CombineModelTips3: "目前仅支持全仓。",
      PleaseSelectApi: "请先设置API",
      ActionSuccessful: "操作成功",
      ActionFailed: "操作失败",
    },
    UserCountModal: {
      SelectSubAccount: "选择交易子账户",
      Save: "保存",
      Cancel: "取消",
      SelectExchange: "选择交易所",
      All: "全部",
      Main: "主",
      Ratio: "比例",
      MaxQty: "最大金额",
      MaxQtyTips: "请输入最大可用金额",
      SyncRatio: "同步比例",
      AvailableBalance: "可用余额",
      WalletBalance: "钱包余额",
      ErrMsg: "错误的api key，请检查此账户设置!",
    },
    TransferModal: {
      UniversalTransfer: "万向划转",
      AssetTransfer: "资金划转",
      Transfer: "划转",
      TransferHistory: "划转记录",
      Cancel: "取消",
      AccountSelect: "账户选择",
      From: "从",
      To: "至",
      Currency: "币种",
      AvailableFunds: "可用资金",
      TransferAmount: "划转数量",
      All: "全部",
      SpotAccount: "现货账户",
      FutureAccount: "U合约账户",
      SwapAccount: "币合约账号",
      FundsAccount: "资金账户",
    },
  },

  AIDashboard: {
    AIDashboardDetail: {
      Group: "交易分组",
      Export: "导出Excel",
      Search: "搜索",
      Optional: "自选",
      Market: "市场",
      Symbol: "交易对",
      Sort: "排名",
      Price: "价格",
      UpDown: "涨跌",
      TREND: "趋势工具",
      STRONG_BACK: "强势高抛低吸",
      SHOCK_GRID: "震荡网格",
      SMART_SHOCK_GRID: "智能震荡网格",
      SHOCK_REVERSE: "高抛低吸",
      MARKETMAKER: "做市策略",
      SOR: "智能路由",
      ARB_OPTION: "期权套利",
      ARB_FACTOR: "因子套利",
      ASTOCK_EXECUTE_MULTI: "A股执行",
      TREND_CONTINUE_ROBOT: "趋势二波",
      RATE_GRID: "汇率网格",
      STRONG_GRID: "强势网格",
      MarkPrice: "标记价格",
      The24hHigh: "24H最高价",
      The24hLow: "24H最低价",
      The24hVolume: "24H成交量",
      The24hQty: "24H成交额",
      SelectEnvironment: "选择运行环境",
      TestEnvironment: "测试环境",
      ProductionEnvironment: "生产环境",
      AwsEnvironment: "AWS环境",
      LinodeEnvironment: "Linode环境",
      Linode2Environment: "Linode2环境",
      Local211Environment: "本地211环境",
      SelectSubAccount: "选择子账户",
      PleaseCancelUpdate: "请先取消更新",
      UnknownStrategyMsg: "未知策略",
      StartSuccessfulMsg: "启动成功",
      StartFailedMsg: "启动失败",
      UpdateSuccessfulMsg: "更新成功",
      UpdateFailedMsg: "更新失败",
      UnknownSymbolMsg: "未知交易对",
      GetSubscribeListFailed: "获取用户订阅列表失败",
    },
    KChart: {
      HistoryMarks: "历史委托",
      CurrentMarks: "当前委托",
    },
    Form: {
      MaxOrderAmount: "最大单量",
      ImpactLevel: "档量",
      ImpactMultiple: "倍率 x",
      Auto: "自动",
      Manual: "手动",
      Level: "档",
      LevelSetting: "订单设置",
      LevelStart: "开始位置",
      LevelTotal: "挂单数",
      LevelInterval: "价格间隔",
      PositionSetting: "仓位设置",
      MultiplePositionsAvailable: "多头头寸",
      ShortPositionAvailable: "空头头寸",
      TargetPosition: "目标仓位",
      AmountAvailable: "可用资金",
      AllAmountAvailable: "总可用资金",
      AvailableAmountOnce: "单次可用",
      RobotN: "只交易流动性前n名",
      PitchRatio: "间距比例",
      OrderStep: "拆单间距",
      OrderQuantity: "委托量",
      Isolate: "逐仓",
      Cross: "全仓",
      Spot: "现货",
      Available: "可用",
      Borrowed: "已借",
      IsolatedMarginNetAssets: "逐仓净资产",
      CrossMarginNetAssets: "全仓净资产",
      SpotNetAssets: "现货余额",
      CanBeOpened: "可开",
      Balance: "余额",
      CustomInstanceName: "自定义实例名称",
      Reset: "重置",
      Cancel: "取消",
      NotLoggedIn: "未登录",
      InsufficientVipLevel: "交易锁定",
      Startup: "启动",
      Update: "更新",
      SmartPrice: "SPF",
      Gear: "档位",
      Window: "窗口",
      Cycle: "周期",
      LongCycle: "长周期",
      ShortCycle: "短周期",
      PriceGap: "价格间距",
      Minimum: "最小",
      Maximum: "最大",
      Volatility: "波动率",
      Trend: "趋势",
      OrderBook: "OIF",
      OFIF: "OFIF",
      MaximumInventory: "最大库存",
      EnableCombination: "启用组合",
      EnableTimeSharing: "启用分时",
      EnableLiquidation: "启用流动性",
      Clearance: "清仓",
      AllClearance: "全部清仓",
      OnlyMaker: "只做Maker",
      FollowBook: "跟随盘口",
      AuthorizedObserver: "授权观察者",
      PositionAvailable: "可用仓位",
      KlineCycle: "K线周期",
      OpenPositionQuantile: "开仓分位数",
      ClosePositionQuantile: "平仓分位数",
      Leverage: "杠杆",
      IsLong: "做多",
      IsUseCurrentPosition: "继承仓位",
      IsNotReQuote: "只报价一次",
      IsOpen: "开仓",
      Side: "方向",
      OnlyLong: "允许做多",
      OnlyShort: "允许做空",
      LongAndShort: "多空双开",
      StrongDecision: "强势判定",
      BuyOrderSpacingRatio: "买单间距比例",
      SellOrderSpacingRatio: "卖单间距比例",
      EquivalentRatio: "等比",
      EquivalentDifferences: "等差",
      TakeProfitStopLoss: "止盈止损",
      TakeProfit: "止盈",
      StopLoss: "止损",
      PriceRange: "价格区间",
      HighPoint: "高点",
      LowPoint: "低点",
      BuyOrder: "买单",
      SellOrder: "卖单",
      PendingOrderLogarithm: "挂单对数",
      SymbolSettings: "交易对设置",
      RateGridLongSymbol: "做多交易对",
      RateGridTradeSymbol: "做空交易对",
      AddCurrentSymbol: "添加交易对",
      AddCurrentCurrency: "添加币种",
      GoBack: "返回",
      Confirm: "确认",

      ExecConfig: "执行器配置",
      ExecMinOrderQty: "单笔最小下单量",
      ExecMaxSplitOrder: "最大拆单数",
      ExecOrderTimeout: "单笔订单超时时间",
      ExecTimeout: "完整超时时间",
      ExecTimeoutTaker: "超时后是否吃单",
    },
    Command: {
      Running: "运行中",
      History: "历史",
      ObserverRunning: "观察者运行中",
      ObserverHistory: "观察者历史",
      RiskRunning: "风控运行中",
      RiskHistory: "风控历史",

      Instance: {
        //期权
        Equity: "账户资产净值",
        TotalEquityUsd: "账户资产净值(美元)",
        Im: "初始保证金率",
        Mm: "维护保证金率",
        TotalFloating_Profit_Loss_Usd: "损益(美元)",
        TotalProfit_Loss: "损益",
        Roi: "ROI",
        Total_Delta: "Delta",
        Total_Gamma: "Gamma",
        Total_Vega: "Vega",
        Total_Theta: "Theta",
        Total_Rho: "Rho",

        SubAccount: "子账户",
        All: "全部",
        SelectionPeriod: "选择时间段",

        StopInstance: "停止实例",
        ChangeParameters: "修改参数",
        ReuseParameters: "复用参数",
        PositionOverview: "仓位概览",
        TradeRecord: "交易记录",
        InitiallyAvailable: "资金规模",
        TotalTransaction: "总成交额",
        StrategicBenefits: "已实现盈亏",
        TotalHoldingProfit: "未实现盈亏",
        TotalProfit: "总盈亏",
        CurrentPosition: "当前仓位",
        PositionDirection: "仓位方向",
        BuyAvgPrice: "买入均价",
        SellAvgPrice: "卖出均价",
        TransactionQty: "成交额",
        AverageHoldingPrice: "持仓均价",
        MarkPrice: "标记价格",
        Notional: "名义价值",
        HoldingProfit: "未实现盈亏",
        PolicyDetails: "策略详情",
        IncomeLevel: "收益水位",
        HighIncomeLevel: "高水位",
        LowIncomeLevel: "低水位",
        MaximumDrawdown: "最大回撤",
        SingleProfit: "单盈利",
        MaximumProfit: "最大盈利",
        MinimumProfit: "最小盈利",
        MaximumLoss: "最大亏损",
        MinimumLoss: "最小亏损",
        ProfitAndLossTimes: "盈亏次数",
        ProfitTimes: "盈利次数",
        LossTimes: "亏损次数",
        ProfitAndLoss: "盈亏",
        TotalProfit2: "总盈利",
        TotalLoss: "总亏损",
        TotalRebate: "总手续费",
        NetProfit: "净盈利",
        NetPosition: "净持仓",
        ProfitAndLossRatio: "盈亏比",
        TransactionVolume: "成交量",
        Buy: "买",
        Sell: "卖",
        Total: "总",
        TransactionRole: "交易角色",
        TakerCount: "Taker次数",
        MakerCount: "Maker次数",
        TakerQty: "Taker成交额",
        MakerQty: "Maker成交额",
        WinRate: "胜率",
        Detail: "查看详情",
        SubAccountBalance: "子账户余额",
        StrategyStatus: "策略状态",
        AccountType: "交易类型",
        StartupTime: "启动时间",
        StopConfirm: "确认停止该实例?",
        StopConfirmTips: "此操作无法撤销!",
        Confirm: "确认",
        Cancel: "取消",
        ActionSuccess: "操作成功",
        ActionFailed: "操作失败",

        CurrentPositionDirection: "当前仓位方向",
        Time: "时间",
        CumBuyU: "累计买入U量",
        CumBuyC: "累计买入币量",
        AvgBuyPrice: "买入均价",
        CumSellU: "累计卖出U量",
        CumSellC: "累计卖出币量",
        AvgSellPrice: "卖出均价",
        InitPosition: "初始持仓数量",
        CurrPosition: "当前持仓数量",
        CurrVolumn: "当前交易量",
        CurrValue: "当前币种敞口价值上限",

        GroupId: "组ID",
        BuyAmount: "买入数量",
        SellAmount: "卖出数量",
        BuyQty: "买入金额",
        SellQty: "卖出金额",
        Profit: "盈亏",
        OrderFee: "手续费",
        StartTime: "开始时间",
        RunTime: "运行时长",
        Action: "操作",

        Day: "天",
        Hour: "小时",
        Minute: "分钟",
        Second: "秒",

        ProfitModal: {
          YieldCurve: "收益曲线",
          CumulativeIncome: "累计收益",
          AverageValue: "平均值",
          SingleIncome: "单笔收益",
          IsLong: "做多",
          IsShort: "做空",
        },
        GroupModal: {
          IntraGroupTransactionRecord: "组内交易记录",
          ShowOnlyTradedOrders: "仅显示已成交订单",
          OrderTime: "挂单时间",
          TransactionTime: "成交时间",
          TransactionDirection: "交易方向",
          TargetPrice: "目标价格",
          TargetAmount: "目标数量",
          AvgPrice: "成交均价",
          Vol: "成交数量",
          Qty: "成交额",
          Role: "成交角色",
          Status: "状态",
          OrderFee: "手续费",
          Total: "总计",
          Record: "条记录",
        },
      },
    },
  },
  ModalInfo: {
    ConfirmCancelExchange: "是否确认撤销该挂单?",
    ConfirmCancelBail: "是否确认撤销委托?",
    ConfirmCancelAllExchange: "是否确认撤销全部挂单?",
    ConfirmMarketClosePosition: "是否确认市价平仓?",
    ConfirmOrder: "是否确认下单?",

    ConfirmTips: "此操作无法撤销!",
    Yes: "是",
    No: "否",
    CancelAction: "取消操作",
    ActionSuccess: "操作成功",
    ActionFailed: "操作失败",
  },

  ExchangeName: {
    Binance: "币安",
    Okex: "OKX",
    Huobi: "火币",
    Coinbase: "COINBASE",
    Deribit: "DERIBIT",
    AISelect: "AI选币",
    Bybit: "BYBIT",
  },
  AccountType: {
    Spot: "现货",
    Future: "U本位合约",
    Swap: "币本位合约",
    Option: "期权",
    Index: "指数",
  },

  OkxAccountType: {
    SPOT: "现货",
    SWAP: "永续",
    SWAPALL: "永续合约",
    FUTURES: "交割",
    FUTURESALL: "交割合约",
  },

  BybitAccountType: {
    SPOT: "现货",
    LINEAR: "U本位合约",
    INVERSE: "反向合约",
    spot: "现货",
    linear: "U本位合约",
    inverse: "反向合约",
    OPTION: "期权",
    Perpetual: "永续",
    Inverse: "反向",
  },

  Kchart: {
    SaveStudy: "保存指标",
    LoadStudy: "加载指标",
  },

  KChartBox: {
    SelectInterval: "选择周期",
    Edit: "编辑",
    Save: "保存",
  },

  FuturesSymbol: {
    GC0W_CMX: "金主连",
    SI0W_CMX: "银主连",
    HG0W_CMX: "铜主连",
    PL0W_NMX: "铂主连",
    CL0W_NMX: "WTI原油主连",
    NG0W_NMX: "天然气主连",
    RB0W_NMX: "RBOB汽油主连",
  },

  AstockSymbol: ASTOCK_LIST,
};
