import { createI18n } from "vue-i18n";

const language = (navigator.language || "en").toLocaleLowerCase(); // 这是获取浏览器的语言
import en from "./lang/en/en";
import zh_CN from "./lang/zh-CN/zh-CN";
import zh_TW from "./lang/zh-TW/zh-TW";

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("lang") || "zh_CN", // 首先从缓存里拿，没有的话就用浏览器语言，
  fallbackLocale: "en", // 设置备用语言
  globalInjection: true,
  messages: {
    en,
    zh_CN: zh_CN,
    zh_TW: zh_TW,
  },
});

export default i18n;
